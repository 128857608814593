<template>
  <div class="content-wrapper">
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1 class="m-0 text-dark">{{ $t('globalTrans.allPickup') }}</h1>
          </div>
          <div class="col-sm-6">
          </div>
        </div><!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>
    <section class="content">
      <div class="container-fluid">
          <div class="card">
              <div class="card-header">
                  <form v-on:keyup.enter="searchData">
                      <div class="row">
                          <div class="col-md-2">
                              <div class="form-group">
                                  <label for="start_date">{{ $t('globalTrans.startDate') }}</label>
                                  <input type="date" id="start_date" v-model="search.start_date" class="form-control" />
                              </div>
                          </div>
                          <div class="col-md-2">
                              <div class="form-group">
                                  <label for="end_date">{{ $t('globalTrans.endDate') }}</label>
                                  <input type="date" id="end_date" v-model="search.end_date" class="form-control" />
                              </div>
                          </div>
                          <div class="col-md-2">
                              <div class="form-group" style="margin-top:28px;">
                                  <button class="btn btn-info btn-sm mr-2" type="submit" @click.prevent="searchData" ><i class="fa fa-search"></i></button>
                                  <button class="btn btn-warning btn-sm" @click.prevent="reload"><i class="fa fa-sync-alt"></i></button>
                              </div>
                          </div>
                      </div>
                  </form>
              </div>
              <div class="card-body">
                <b-overlay :show="loader">
                    <div class="table-responsive">
                        <table class="table table-bordered table-striped table-sm">
                            <thead>
                                <tr class="text-center">
                                    <th>{{ $t('globalTrans.merchant') }}</th>
                                    <th>{{ $t('globalTrans.total_parcel_req') }}</th>
                                    <th>{{ $t('globalTrans.rider_picked') }}</th>
                                    <th>{{ $t('globalTrans.address') }}</th>
                                    <th>{{ $t('globalTrans.hub') }}</th>
                                    <th>{{ $t('globalTrans.rider') }}</th>
                                    <th>{{ $t('globalTrans.date') }}</th>
                                    <th>{{ $t('globalTrans.time') }}</th>
                                    <th>{{ $t('globalTrans.age') }}</th>
                                    <th style="text-align:center;">{{ $t('globalTrans.action') }}</th>
                                </tr>
                            </thead>
                            <!-- <tfoot>
                                <tr class="text-center">
                                    <th>{{ $t('globalTrans.merchant') }}</th>
                                    <th>{{ $t('globalTrans.total_parcel_req') }}</th>
                                    <th>Rider Picked</th>
                                    <th>Address</th>
                                    <th>{{ $t('globalTrans.hub') }}</th>
                                    <th>{{ $t('globalTrans.rider') }}</th>
                                    <th>{{ $t('globalTrans.date') }}</th>
                                    <th>Time</th>
                                    <th>Age</th>
                                    <th>{{ $t('globalTrans.action') }}</th>
                                </tr>
                            </tfoot> -->
                            <tbody>
                                <tr v-for="(pickup,key) in listData" :key="key">
                                    <td>{{ pickup.merchant_business }}</td>
                                    <td>{{ pickup.total_parcel }}</td>
                                    <td>{{ pickup.rider_picked }}</td>
                                    <td>{{ pickup.address }}</td>
                                    <td>{{ pickup.hub_name }}</td>
                                    <td>{{ pickup.rider_name }}</td>
                                    <td>{{ pickup.date }}</td>
                                    <td>{{ getTimeSlot(pickup.time) }}</td>
                                    <td>{{ pickup.age ? pickup.age : '-' }}</td>
                                    <td>  
                                       <button v-if="pickup.status == 2" class="btn btn-success btn-sm mr-1" @click="pickedModal(pickup)" title="Picked"><i class="fas fa-check"></i></button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="mt-3">
                        <b-pagination
                            v-model="pagination.currentPage"
                            :per-page="pagination.perPage"
                            :total-rows="pagination.totalRows"
                            @input="searchData"
                        />
                    </div>
                </b-overlay>
            </div>
          </div>
      </div>
    </section>
    
    <div v-if="showPickedModal" class="modal fade show" style="display:block" tabindex="-1" id="addHubModal" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <form v-on:keyup.enter="createPickup">
                <b-overlay :show="loader">
                    <div class="modal-content">
                        <div class="modal-header bg-info text-white">
                            <h5 class="modal-title text-center w-100"> {{ $t('globalTrans.pickup') }}</h5>
                            <button type="button" class="close" aria-label="Close" @click="cancelModal">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <ValidationObserver ref="form" v-slot="{ handleSubmit }">
                                <form @submit.prevent="handleSubmit(createPickup)">
                                    <div class="row">
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                            <ValidationProvider name="Total Parcel" vid="total_parcel" v-slot="{errors}" rules="required">
                                                <div class="form-group">
                                                    <label for="total_parcel">{{ $t('globalTrans.total_parcel_req') }} <span class="text-danger" title="Required">*</span></label>
                                                    <input type="text" id="total_parcel" v-model="form_edit.total_parcel" class="form-control" disabled>
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div> 
                                              </ValidationProvider>                       
                                        </div>
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                            <ValidationProvider name="Rider Picked" vid="rider_picked" v-slot="{errors}" rules="required">
                                                <div class="form-group">
                                                    <label for="rider_picked">Rider Picked <span class="text-danger" title="Required">*</span></label>
                                                    <input type="text" id="rider_picked" v-model="form_edit.rider_picked" class="form-control" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');">
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div> 
                                              </ValidationProvider>                       
                                        </div>
                                    </div>
                                </form>
                            </ValidationObserver>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-info" @click.prevent="pickedUp">{{ $t('globalTrans.submit') }}</button>
                            <button type="button" class="btn btn-danger" @click="cancelModal">{{ $t('globalTrans.cancel') }}</button>
                        </div>
                    </div>
                </b-overlay>
            </form>
        </div>
    </div>
  </div>
</template>
<script>
  import config from '@/config' 
  import moment from 'moment'
  
  export default {
    name:'Pickup',
    data() {
      return {
        loader: false,
        search : {
          merchant_id: 0,
          store_id: 0,
          hub_id: 0,
          rider_id: 0,
          start_date: moment().subtract(31,'d').format('YYYY-MM-DD'),
          end_date: moment().format('YYYY-MM-DD')
        },
        key:'',
        form_edit: '',
        showPickedModal :false,
        pagination: {
            currentPage: 1,
            totalRows: 0,
            perPage: this.$store.state.commonObj.perPage,
            slOffset: 1
        },
        storeList: []
      }
    },
    created () { 
      this.loadData()
    },
    computed : {
      commonObj () {
        return this.$store.state.commonObj
      },
      stateReload () {
          return this.$store.state.stateReload
      },
      listData () {
          return this.$store.state.list
      },
      authUser () {
        return this.$store.state.authUser
      }
    },
    watch: {
        stateReload : function (newVal, oldVal) {
            if (newVal != oldVal) {
                this.loadData()
            }
        }
    },
    methods: {
        reload () {
            this.search = Object.assign({}, {
                start_date: moment().subtract(31,'d').format('YYYY-MM-DD'),
                end_date: moment().format('YYYY-MM-DD')
            })
        },
        searchData () {       
            this.loadData()         
        },
        async loadData(){     
            this.loader = true
            const params = Object.assign({}, this.search, { rider_id: this.authUser.id, page: this.pagination.currentPage, per_page: this.pagination.perPage })              
            const response = await config.getData('/rider/pickup/list', params)
            this.loader = false
            if (response.success) {
                this.$store.dispatch('setList', this.getRelationalData(response.data.data))
                this.paginationData(response.data)  
            } else {
                this.$store.dispatch('setList', [])
                this.paginationData([])  
            }
        },
        getRelationalData (data) {
            var rightNow = moment().format("YYYY-MM-DD HH:mm:ss")
            var today = moment().format("YYYY-MM-DD")
            const datas = data.map(item => {
                const merchant = this.commonObj.merchantList.find(el => el.id === item.merchant_id)
                const hub = this.commonObj.hubList.find(el => el.id === item.hub_id)
                const rider = this.$store.state.commonObj.riderList.find(el => el.id === item.rider_id)

                const tmpAge = (today === item.date) ? (moment(rightNow).diff(item.created_at, 'hours') + ' hour ' + moment(rightNow).diff(item.created_at, 'minutes') + ' minutes') : ''

                const merchantObj = { merchant_business : typeof merchant !== 'undefined' ? merchant.text : '', age: tmpAge }
                const hubObj = { hub_name : typeof hub !== 'undefined' ? hub.text : '' }
                const riderObj = { rider_name : typeof rider !== 'undefined' ? rider.text : '' }

                return Object.assign({}, item, merchantObj, hubObj, riderObj)
            })
            return datas
        },
        paginationData (data) {
            this.pagination.currentPage = data.current_page
            this.pagination.totalRows = data.total
            this.pagination.slOffset = this.pagination.perPage * this.pagination.currentPage - this.pagination.perPage + 1
        },
        pickedModal (item) {
            this.form_edit = item
            this.showPickedModal = true
        },
        async pickedUp () {
            this.loader = true
            this.$store.dispatch('stateReload', true)
            const response = await config.postData(`/rider/pickup/picked`, this.form_edit)                
            this.loader = false
            if (response.success) {  
                this.showPickedModal = false 
                this.$toast.success({
                    title: 'Success',
                    message: response.message,
                    color: '#218838'
                })
            } else {
                this.$toast.error({
                    title: 'Error',
                    message: response.message,
                    color: '#dc3545'
                })
            }
            this.$store.dispatch('stateReload', false)  
        },
        getTimeSlot (time) {
            if (time == 1) {
                return '12:00 PM to 4:00 PM'
            } else if (time == 2) {
                return '4:00 PM to 8:00 PM'
            } else if (time == 3) {
                return '8:00 PM to 12:00 PM'
            }
        },
        cancelModal(){
            this.showPickedModal = false
            document.body.classList.remove("modal-open");
        }
    }
  }
</script>

<style scoped>
    .form-group select2 {
        width: 218px !important;
    }

    @media only screen and (max-width: 760px),
	(min-device-width: 768px) and (max-device-width: 1024px)  {
	
		/* Force table to not be like tables anymore */
		table, thead, tbody, th, td, tr { 
			display: block; 
		}

        .table-sm th, .table-sm td {
            padding: 0.3rem;
            padding-left: 50%;
        }
		
		/* Hide table headers (but not display: none;, for accessibility) */
		thead tr { 
			position: absolute;
			top: -9999px;
			left: -9999px;
		}
		
		tr { 
            border: 1px solid #ccc;
            margin-bottom: 7px; 
        }
		
		td { 
			/* Behave  like a "row" */
			border: none;
			border-bottom: 1px solid #eee; 
			position: relative;
			padding-left: 50%; 
		}
		
		td:before { 
			/* Now like a table header */
			position: absolute;
			/* Top/left values mimic padding */
			top: 6px;
			left: 6px;
			width: 45%; 
			padding-right: 10px; 
			white-space: nowrap;
		}
		
		/*
		Label the data
		*/
		td:nth-of-type(1):before { content: "Merchant" }
		td:nth-of-type(2):before { content: "Total Parcel Requested"; }
		td:nth-of-type(3):before { content: "Rider Picked"; }
		td:nth-of-type(4):before { content: "Address"; }
		td:nth-of-type(5):before { content: "Hub"; }
		td:nth-of-type(6):before { content: "Rider"; }
		td:nth-of-type(7):before { content: "Date"; }
		td:nth-of-type(8):before { content: "Time"; }
		td:nth-of-type(9):before { content: "Age"; }
		td:nth-of-type(11):before { content: "Action"; }
	}
	
	/* Smartphones (portrait and landscape) ----------- */
	@media only screen
	and (min-device-width : 320px)
	and (max-device-width : 480px) {
		body { 
			padding: 0; 
			margin: 0; 
			width: 320px; }
		}
	
	/* iPads (portrait and landscape) ----------- */
	@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
		body { 
			width: 495px; 
		}
	}
</style>